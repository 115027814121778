/*
File Name: 		custom.css
Description:  You can add your custom CSS here and it will overwrite template styles
*/
.cursor { display: none; }
#landing-menu-new {
    color: #fff;
    font-weight:700;
    margin: 40px auto 0px auto;
    text-transform: uppercase;
    text-decoration: none;
    list-style:none;
}
#landing-menu-new .nav-bar-new {    
    padding-left: 0px;
    background-color: #00000061;
    border-radius: 4px;
}
#landing-menu-new .nav-bar-new li { display:inline; padding: 0 15px; }
#landing-menu-new .nav-bar-new li:hover { border-bottom: 1px solid #a20711; color: #a20711; }

.btn-primary:focus {
    background-color: #a20711;
}
.btn-primary:active {
    background-color: #a20711;
}
